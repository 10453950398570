@import-normalize;

@font-face {
  font-family: "Maax Rounded";
  src: url('./assets/fonts/Maax\ Rounded.otf');
}

@font-face {
  font-family: "Maax Rounded Bold";
  src: url('./assets/fonts/Maax\ Rounded\ Bold.otf');
}

@font-face {
  font-family: "Maax Rounded Bold Italic";
  src: url('./assets/fonts/Maax\ Rounded\ BoldItalic.otf');
}

@font-face {
  font-family: "Maax Rounded Italic";
  src: url('./assets/fonts/Maax\ Rounded\ Italic.otf');
}

@font-face {
  font-family: "Maax Rounded Medium";
  src: url('./assets/fonts/Maax\ Rounded\ Medium.otf');
}

@font-face {
  font-family: "Maax Rounded Medium Italic";
  src: url('./assets/fonts/Maax\ Rounded\ MediumItalic.otf');
}





* {
  box-sizing: border-box;
}

#root{
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{
  height: 100%;
  width: 100%;
  background-color: gray;
}


.container{
  position: relative;
  width: 480px;
  height: 854px;
  margin: auto;
  background-color: white;
}

.hidden{
  display: none;
}


.referenceWarning{
  position: absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  top: -180px;
  width: 90%;
  margin: 0% 5%;
  height:170px;
  background-color: #E66100;
  color: white;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
  z-index: 10;
}

.referenceWarning.Active{
  animation: 1s ease-out 0s 1 slideInFromTop;
  margin: 5% 5%;
  top: 10px;
}

.referenceWarning h1{
  text-align: center;
  margin: 0;
}

.referenceWarning h2{
  text-align: center;
  margin: 0;
  color: white;
  font-size: 18px;
}

.uploadSheild{
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(77, 77, 77, 0.75);
  z-index: 9;
  color: white;
  display: none;
}

.uploadSheild.Active{
  display:flex;
}


@media only screen and (max-width: 768px)  {
  .container{
    width: 100%;
    height: 100%;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}