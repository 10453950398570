.PageMenu{
	display: grid;
  	grid-template-rows: repeat(auto-fit) ;
	height: 100%;
	margin-top: 33px;
}

.PageMenu .Page{
	height: 125%;
	width: 100%;
	border: 3px solid #FFFFFF;
	border-radius: 0px 50px 0px 0px;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}
.PageMenu .Page h2{
	color: white;
	line-height: 26px;
}

.PageMenu .Page:last-child{
	bottom:0;
}

.Page .PageTitleContainer{
	height: 75px;
	padding-left: 75px;
}

.PageTitle{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	
}

.PageTitle h2{
	font-family: 'Maax Rounded';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 18px;
	/* or 75% */

	display: flex;
	align-items: center;
}