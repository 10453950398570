.InformationLayoutContainer{
	padding: 24px;
}

.InformationLayout{
	height: 100%;
	width: 90%;
	border: 2.5px solid #00B74F;
	border-radius: 0 100px;
	margin: auto;
	margin-top: 20px;
	padding: 27px;
}

.InformationLayout p{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.InformationLayout h2{
	font-family: 'Maax Rounded';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	color: #111111;
}

.InformationLayout h3{
	font-family: 'Maax Rounded';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	margin-top: 0;

	color: #828282;
}

.InformationLayout address{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	
	color: #000000;
}