.about{
	height: 100%;
	display: flex;
	flex-direction: column;
}

.about p{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color:#000000;
}

.about .InformationLayout .address{
	margin-top: 20px;
}

.about .InformationLayout p{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}