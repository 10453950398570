.thankyou{
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #2D2D51;
	justify-content: space-between;
}


.thankyou h1{
	font-family: 'Maax Rounded';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 41px;
	text-align: center;
	margin-bottom: 10px;
}

.thankyou h2{
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	margin: auto;
	margin-bottom: 10px;
	width: 85%;
}

.thankyou .imageContainer{
	width: 85%;
	margin: auto;
	height: 60%;
}

.thankyou .imageContainer img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.thankyou .caption{
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: rgba(0, 0, 0, 0.3);
	margin-top: 5px;
	margin-bottom: 20px;
}