.navigationButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
}

.navButton{
    display: flex;
    background-color: #00B74F;
    color: white;
    justify-content: center;
	font-weight: bold;
	cursor: pointer;
    width: 115px;
    height: 51px;
    border-radius: 26px;
    text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.navButton > div{
    margin: auto;
}

.navButton.back{
    background-color: #fff;
    color: #00B74F;
    border: 2px solid #00B74F;
}