.live{
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 10px;
}

.live .ColorWheelContainer{
	width: 85%;
	margin: auto;
}

.live_buttons{
	width: 85%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: fit-content;
	margin: auto;
}

.live_buttons >div{
	display: flex;
	flex-direction: column;
}

.live_buttons >div >p{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #939598;
}

.live_buttons .live_button{
	border-radius: 10px;
	width: 90px;
	height: 90px;
	background-color: #A8ACAE;
	display: flex;
	-webkit-transition-duration: 0.5s; /* Safari */
	transition-duration: 0.5s;
	pointer-events: all;
}

.live_buttons .live_button:active{
	background-color: #8A57A4;
	transform: translateY(-10px);
	transition: all 0.25s;
}

.live_buttons .live_button:active:after{
	transition: 0s;
}

.iconContainer{
	margin: auto;
}