.QrCode{
	height:100%;
}
.QrCode .wrapper{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.wrapper div{
	width: fit-content;
}